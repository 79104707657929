import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import QrDownload from "../Assets/qr-download.png";


const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Track Expenses",
      text: "Easily add your expenses and incomes in an easy way to keep track of your cash flow.",
    },
    {
      image: ChooseMeals,
      title: "Analyse Your Stats",
      text: "Keep track of where your money goes creating categories and subcategories.",
    },
    {
      image: DeliveryMeals,
      title: "Create Budgets",
      text: "Create budgets by setting goals per category and time. Limit your spending and increase your wealth.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        {/* <p className="primary-subheading">Work</p> */}
        <h1 className="primary-heading">Soldi app for Apple and Android</h1>
        <p className="primary-text">
        Improve your personal finance management with Soldi, designed to simplify your financial tracking.  
        {/* Easily input your expenses and categorize them for effortless monitoring. Utilize analysis tools to gain insights into your spending habits and set personalized budgets, receiving timely alerts to help you stay on track with your financial goals. */}
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Download for iOS</button>
          <button className="secondary-button">Download for Android</button>
          {/* <button className="watch-video-button">
            <BsFillPlayCircleFill /> Download for Android
          </button> */}
        </div>
        <div className="work-section-image-container">
        <img src={QrDownload} alt="" />
      </div>

      </div>
      {/* <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Work;
